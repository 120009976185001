import React from 'react';

import { Dropdown } from '@hcs/design-system';
import { UpdateOptions } from '@hcs/types';

import { AGILE_OPS_UPDATE_OPTIONS } from '../../constants/orderItem.constants';

import styles from './AgileOpsUpdateDropdown.module.css';

interface Props {
  className?: string;
  onSelect: (val: UpdateOptions) => void;
  value: UpdateOptions | null;
}
const dataHcName = 'agile-ops-update-dropdown';
export const AgileOpsUpdateDropdown = ({
  className,
  onSelect,
  value,
}: Props) => {
  return (
    <Dropdown
      dataHcName={dataHcName}
      className={className}
      options={AGILE_OPS_UPDATE_OPTIONS}
      onSelect={onSelect}
      value={value}
      placeholder="Reason Code"
      theme={{
        OptionsContainer: styles?.OptionsContainer,
      }}
    />
  );
};
